<template>
    <div class="container">
        <div class="header_common_box">
            <v-header :params="{ title, leftIcon: true}"></v-header>
        </div>
        <div class="content">
            <span>微信号</span>
            <input type="tel" placeholder="微信号或微信绑定的手机号">
        </div>
        <div class="submitBtn">
            <button>确定</button>
        </div>
        <div class="desc">
            <p>【重要】：</p>
            <p>重要事情我们会主动联系您，请您完善个人联系方式，微信或者QQ至少填写其中一个</p>    
        </div>
    </div>
</template>
<script>
import vHeader from "@/components/header.vue";
import isWeixin from '@/utils/utils.js'

export default {
    data(){
        return{
            title:this.$route.meta.title,
        }
    },
    components:{
        vHeader
    },
    mounted:function(){
    //判断网页是什么打开
        let isWeiXin = isWeixin()
        if(isWeiXin){
        this.showHeader = false
        }
        if(this.showHeader==false){
        document.title = ''
        }
    },
}
</script>
<style lang="less" scoped>
    .container{
        .content{
            width: 100%;
            border-bottom: 1px solid #ccc;
            font-size: 14px;
            height: 40px;
            line-height: 40px;
            span{
                display: inline-block;
                font-size: 14px;
                padding: 0 10px 0 10px;
            }
            input{
                width: 180px;
                border: none;
                background-color: rgba(0, 0, 0, 0);
            }
        }
        .submitBtn{
            text-align: center;
            width: 100%;
            height: 60px;
            button{
                width: 60%;
                height: 36px;
                border: none;
                background-color: #d25555;
                color: #fff;
                border-radius: 6px;
                font-size: 18px;
                margin: 36px 0;
            }
        }
        .desc{
            height: 140px;
            font-size: 12px;
            color: #555;
            width: 94%;
            margin: 60px auto;
            p{
                padding: 0;
                margin: 0;
            }
        }
    }
</style>